
import Vue from 'vue';
import _clone from 'lodash.clonedeep';
import { formatDistanceToNow } from 'date-fns';
import { zonedTimeToUtc } from 'date-fns-tz';
import { CurrentSection, LayoutSection } from '../../../../types/layout';
import AddModuleC360 from './addModuleC360.vue';
import { C360Icon } from '@c360/ui';
import ExitEditDialog from './exitEditDigalog.vue';
import utils from '../../../../util';
import analytics from '../../../../mixins/analytics';

export default Vue.extend({
  name: 'layoutEditorOptionsC360',
  components: { AddModuleC360, ExitEditDialog, C360Icon },
  data: (): {
    showResetDialog: boolean;
    showResetAllDialog: boolean;
    showSaveDialog: boolean;
    showExitEditDialog: boolean;
    showNoComponentsDialog: boolean;
    defaultComponentList: Array<string> | null;
    saveOption: string;
    saveloading: boolean;
    componentEditorOpen: boolean;
    reverted: string | null;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    layout: any; // LayoutSection | null;
    smallerScreen: boolean;
    open: boolean;
    demoAdvertiserIds: Array<string>;
  } => ({
    showResetDialog: false,
    showResetAllDialog: false,
    showSaveDialog: false,
    showExitEditDialog: false,
    showNoComponentsDialog: false,
    defaultComponentList: null,
    saveOption: 'advertiser',
    saveloading: false,
    componentEditorOpen: false,
    reverted: null,
    layout: null,
    smallerScreen: false,
    open: false,
    demoAdvertiserIds: [
      '88888888',
      'C660D61F5BE889D669014291AD8ACA7F',
      '08D238FC1269F0783A05C7875F948844',
      'F0E3148242F2F55BE7B0218FF2508E27',
    ],
  }),
  props: ['currentSection'],
  mixins: [analytics],
  created() {
    this.layout = this.$store.state?.customer?.dynamicLayout?.layoutCustomizations;
  },
  mounted() {
    if (this.layout?.type !== 'default') {
      this.saveOption = this.layout.type;
    }
    window.addEventListener('resize', this.onResize);
    this.onResize();
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
  },
  computed: {
    enableSave(): boolean {
      return this.$store.state.layoutEditor.enableSave;
    },
    currentAgency(): string {
      return Vue.prototype.$c360.portalName;
    },
    currentTactic(): string {
      return this.layout?.tactic ? utils.getTacticName(this.layout.tactic) : '';
    },
    selectedAdvertiserName(): string {
      if (this.$store?.state?.advertiser?.advertiserInfo) {
        const { data } = this.$store.state.advertiser.advertiserInfo;
        if (data.name) {
          return data.name;
        }
      }
      return 'this advertiser';
    },
    themeColor(): string {
      return this.Theme.light.primary ? this.Theme.light.primary : 'green';
    },
    version(): string {
      return this.$store.state.customer.dynamicLayout.layoutCustomizations.Version;
    },
    advertiserEdit(): boolean {
      return utils.hasRight(this.$store.state.customer.user, ['CUSTOMIZE_ADVERTISER']);
    },
    agencyEdit(): boolean {
      return utils.hasRight(this.$store.state.customer.user, ['CUSTOMIZE_AGENCY']);
    },
    saveOptions(): Array<{ text: string; value: string }> {
      const optionArray: Array<{ text: string; value: string }> = [];
      if (this.agencyEdit) optionArray.push({ text: this.agencyTypeName, value: 'agency' });
      if (this.advertiserEdit) optionArray.push({ text: 'Advertiser', value: 'advertiser' });
      return optionArray;
    },
    currentLayoutTitle(): string {
      const name = this.layout?.type === 'agency' ? this.agencyTypeName : this.layout?.type;
      return name.charAt(0).toUpperCase() + name.slice(1);
    },
    resetTitle(): string {
      return this.layout?.type === 'advertiser' ? 'Previous' : 'Default';
    },
    isAdvertiserLayout(): boolean {
      return this.layout?.type === 'advertiser';
    },
    isDisabled(): boolean {
      return this.isAdvertiserLayout && this.saveOption === 'agency';
    },
    resetDisabled(): boolean {
      // prevent resetting to agency if user does not have the rights.
      if (this.layout?.type === 'agency' && !this.agencyEdit) {
        return true;
      }
      return this.layout?.type === 'default';
    },
    agencyId(): string {
      return utils.getAgencyFromURL();
    },
    agencyTypeName(): string {
      return utils.getAgencyFromURL() === 'sinclair' ? 'Media Company' : 'Agency';
    },
    modifiedDate(): string {
      return this.layout?.LastModifiedDate
        ? formatDistanceToNow(zonedTimeToUtc(utils.normalizeDate(this.layout.LastModifiedDate), 'UTC'))
        : '';
    },
    onSummaryPage(): boolean {
      return this.$route.query?.tab === 'summary';
    },
    isDemoTestAdvertiser(): boolean {
      return this.demoAdvertiserIds.includes(this.$route.query.id);
    },
    isShowResetAllTacticsLayout(): boolean {
      const isExistPermission = this.$store.state.customer?.user?.permissions.includes('SHOW_RESET_ALL_TACTICS_LAYOUT');
      const isAHSU = this.$store.state.customer?.user?.is_superuser;
      return (isExistPermission || isAHSU) && this.isDemoTestAdvertiser;
    },
  },
  methods: {
    setCurrentSection(section: CurrentSection): void {
      this.$emit('set-current-section', section);
    },
    async previousVersion(type: string): Promise<string> {
      // returns the previous version number of the layout.
      const version = await this.$store
        .dispatch('getLayoutsGQL', {
          tactic: this.$route.query?.tab || 'summary',
          agency: type === 'agency' ? this.agencyId : '',
          advertiserId: type === 'advertiser' ? this.$route.query?.id : '',
          checkPrevious: true,
        }) // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .then((layout: any): string => {
          if (layout?.layoutCustomizations) {
            return layout.layoutCustomizations.Version;
          } else return null;
        })
        .catch((err: Error): string => {
          // eslint-disable-next-line no-console
          console.error(err);
          return null;
        });
      return version;
    },
    async saveLayout(reset: boolean): Promise<void> {
      this.saveloading = true;
      const { customer } = this.$store.state;
      const currentType = this.layout.type;
      // set agency name if first save
      if (!customer.dynamicLayout.layoutCustomizations.agencyName) {
        this.$store.dispatch('setLayoutAgencyName', this.agencyId);
      }
      if (!customer.dynamicLayout.layoutCustomizations.advertiserId) {
        this.$store.dispatch('setLayoutAdvertiserId', this.$store.state.customer.selection.advertiserId.toLowerCase());
      }

      // checking if previous advertiser layout exisits that has not been deleted yet and getting the latest version
      const version = await this.previousVersion(reset ? currentType : this.saveOption);
      if (version !== null) this.$store.dispatch('setLayoutVersion', version);

      // remove null/__type values from customizations and layout
      let currCustomizations = utils.removeNullFromObjectOrArray(this.$store.state.layoutEditor.currCustomizations);
      const cLayout = utils.removeNullFromObjectOrArray(this.$store.state.customer.dynamicLayout.layoutCustomizations);
      currCustomizations = currCustomizations.map(c => {
        if (c.overrides) c.overrides.disabled = false;
        return c;
      });
      const tempComponents = cLayout.config.components[0].components.map(c => {
        c.disabled = false;
        return c;
      });
      cLayout.config.components[0].components = tempComponents;
      // console.log({ currCustomizations, cLayout });
      this.$store
        .dispatch('saveLayoutCustomizations', {
          layout: cLayout,
          customizations: currCustomizations,
          type: reset ? currentType : this.saveOption,
          reset,
        })
        .then(resp => {
          if (!resp.error) {
            this.analyticTrack(
              this.trackValue.EVENT_CONFIGURABILITY,
              `${this.trackValue.SAVE_CHANGE_TO} - ${this.saveOption}`,
            );
            this.$store
              .dispatch('getLayoutsGQL', {
                agency: this.agencyId,
                advertiserId: this.$route.query?.id,
                tactic: this.$route.query?.tab,
              })
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              .then((newLayout: any): void => {
                if (newLayout) {
                  this.$store.dispatch('setLayoutType', newLayout.layoutCustomizations.type);
                  if (newLayout.layoutCustomizations?.type !== 'default') {
                    this.saveOption = newLayout.layoutCustomizations.type;
                  }
                  this.layout = newLayout.layoutCustomizations;

                  const layoutCopy = _clone(newLayout);
                  if (reset) {
                    const defaultComps = layoutCopy.layoutCustomizations.config.components.find(
                      c => c.type !== 'summaryonly',
                    );
                    this.defaultComponentList = defaultComps.components.map(comp => {
                      return {
                        cid: comp.cid,
                        overrides: comp.overrides,
                        id: comp.id,
                      };
                    });
                    this.analyticTrack(
                      this.trackValue.EVENT_CONFIGURABILITY,
                      `${this.trackValue.RESET_TO} - ${this.resetTitle}`,
                    );
                    this.$store.dispatch('layoutEditor/setCurrCustomizations', this.defaultComponentList).then(() => {
                      // so it resets in real time without needing to refresh
                      const storeComps =
                        this.$store.state.customer.dynamicLayout.layoutCustomizations.config.components.find(
                          c => c.type !== 'summaryonly',
                        );
                      storeComps.components = defaultComps.components;
                    });
                  }
                  const section: LayoutSection = { ...newLayout.layoutCustomizations.config };
                  this.$emit('set-current-section', section);
                  this.$store.dispatch('setLayoutSource', layoutCopy).then().catch();
                  this.$store.dispatch('layoutEditor/setEnableSave', false);
                  this.saveloading = false;
                  this.showResetDialog = false;
                  this.showSaveDialog = false;
                }
              })
              .catch((error: Error) => {
                // eslint-disable-next-line no-console
                console.log(error);
                this.saveloading = false;
              });
          }
        })
        .catch((err: Error): void => {
          // eslint-disable-next-line no-console
          console.error(err);
          this.saveloading = false;
        });
    },
    checkComponents(): void {
      if (this.$store.state.layoutEditor.currCustomizations.length !== 0) {
        this.showSaveDialog = true;
      } else this.showNoComponentsDialog = true;
    },
    exitDesignMode(): void {
      if (this.enableSave) {
        this.showExitEditDialog = true;
      } else {
        this.$store.dispatch('layoutEditor/setCurrCustomizations', null);
        this.$store.dispatch('layoutEditor/toggleEditMode', false);
        this.$store.dispatch('layoutEditor/closeEditComponent');
        this.showExitEditDialog = false;
        setTimeout(() => {
          window.dispatchEvent(new Event('resize'));
        }, 1000);
      }
    },
    hideDialog(): void {
      this.showExitEditDialog = false;
    },
    formatDistanceToNow(date: Date): string {
      try {
        if (!date) return '';
        return formatDistanceToNow(date);
      } catch (err) {
        // eslint-disable-next-line no-console
        console.log('formatDistanceToNow error', date, err);
      }
      return '';
    },
    onResize(): void {
      this.smallerScreen = window.innerWidth < 1290;
    },
    getSingleLayout(reset): void {
      this.$store
        .dispatch('getLayoutsGQL', {
          agency: this.agencyId,
          advertiserId: this.$route.query?.id,
          tactic: this.$route.query?.tab,
        })
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .then((newLayout: any): void => {
          if (newLayout) {
            this.$store.dispatch('setLayoutType', newLayout.layoutCustomizations.type);
            if (newLayout.layoutCustomizations?.type !== 'default') {
              this.saveOption = newLayout.layoutCustomizations.type;
            }
            this.layout = newLayout.layoutCustomizations;

            const layoutCopy = _clone(newLayout);
            if (reset) {
              const defaultComps = layoutCopy.layoutCustomizations.config.components.find(
                c => c.type !== 'summaryonly',
              );
              this.defaultComponentList = defaultComps.components.map(comp => {
                return {
                  cid: comp.cid,
                  overrides: comp.overrides,
                  id: comp.id,
                };
              });
              this.analyticTrack(
                this.trackValue.EVENT_CONFIGURABILITY,
                `${this.trackValue.RESET_TO} - ${this.resetTitle}`,
              );
              this.$store.dispatch('layoutEditor/setCurrCustomizations', this.defaultComponentList).then(() => {
                // so it resets in real time without needing to refresh
                const storeComps = this.$store.state.customer.dynamicLayout.layoutCustomizations.config.components.find(
                  c => c.type !== 'summaryonly',
                );
                storeComps.components = defaultComps.components;
              });
            }
            const section: LayoutSection = { ...newLayout.layoutCustomizations.config };
            this.$emit('set-current-section', section);
            this.$store.dispatch('setLayoutSource', layoutCopy).then().catch();
            this.$store.dispatch('layoutEditor/setEnableSave', false);
            this.saveloading = false;
            this.showResetDialog = false;
            this.showSaveDialog = false;
            this.showResetAllDialog = false;
          }
        })
        .catch((error: Error) => {
          // eslint-disable-next-line no-console
          console.log(error);
          this.saveloading = false;
        });
    },
    async resetAllTacticLayouts() {
      this.saveloading = true;
      const advertiserId = this.$route.query.id?.toLowerCase();

      await this.resetLayouts(advertiserId);
      await this.resetSpending(advertiserId);
      await this.getSingleLayout(true);
    },
    prepareLayoutCustomization(customizations, layoutCustomizations) {
      const advertiserId = this.$route.query.id?.toLowerCase();
      if (customizations && layoutCustomizations) {
        let currCustomizations = utils.removeNullFromObjectOrArray(customizations);
        const cLayout = utils.removeNullFromObjectOrArray(layoutCustomizations);
        currCustomizations = currCustomizations?.map(c => {
          const customization = {
            cid: c.cid,
            id: c.id,
          };
          if (c.overrides) {
            c.overrides.disabled = false;
            customization['overrides'] = c.overrides;
          }
          return customization;
        });
        return { layout: { ...cLayout, advertiserId: advertiserId }, customizations: currCustomizations };
      }
    },
    resetLayouts(advertiserId) {
      return new Promise(async resolve => {
        const products = this.$store.state?.customer?.currentDashboard?.products;
        const payloadsSingleLayout = products.map(product => ({
          agency: '',
          advertiserId,
          tactic: product.id,
          checkPrevious: true,
        }));

        const fetchData = async payload => {
          const response = await this.$store.dispatch('getLayoutsGQL', payload);
          let layoutCustomization = null;
          if (response) {
            layoutCustomization = this.prepareLayoutCustomization(
              response.defaultLayout?.config?.components[0]?.components,
              response.defaultLayout,
            );
          }

          if (layoutCustomization) {
            const payload = {
              ...layoutCustomization,
              reset: true,
              type: 'advertiser',
            };
            await this.$store.dispatch('saveLayoutCustomizations', payload);
          }
        };

        const res = await Promise.all(payloadsSingleLayout.map(payload => fetchData(payload)));

        this.saveloading = false;
        this.showResetAllDialog = false;

        resolve(res);
      });
    },
    resetSpending(advertiserId) {
      const campaignlist = this.$store.state?.customer?.campaigns?.campaignlist;
      const payloadsForResetSpending = campaignlist.map(campaign => ({
        id: advertiserId,
        campaignId: campaign.id,
        campaignSource: campaign.FeedSource,
        type: 'Hide',
        value: '',
      }));

      payloadsForResetSpending.forEach(async payload => {
        await this.$store.dispatch('setCampaignSpendType', { data: payload });
      });

      this.saveloading = false;
      this.showResetAllDialog = false;
    },
  },
  watch: {
    '$store.state.layoutEditor.componentId': {
      handler(): void {
        // disable global save when component editor open
        if (this.$store.state.layoutEditor.componentId) {
          this.componentEditorOpen = true;
        } else {
          this.componentEditorOpen = false;
        }
      },
    },
  },
});
